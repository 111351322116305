import React from "react";
import shallow from "zustand/shallow";
import { useNotificationStore } from "../../utils/errors";
import Notification from "./Notification";

export default () => {
  const notifications = useNotificationStore((state) => state.notifications, shallow);

  return (
    <div>
      {notifications?.map((notification) => (
        <Notification type={notification.type} notificationID={notification.id} key={notification.id}>
          {notification.text}
        </Notification>
      ))}
      {/* <Notification type="success" notificationID="abc">
        "Here we are. Fix me!!!"
      </Notification> */}
    </div>
  );
};
