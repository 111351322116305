import create from "zustand";
import { authenticationService, UserDataType } from "./authenticationService";

interface State {
  user?: UserDataType;
}

const useStore = create<State>((set) => ({
  user: authenticationService.currentUser(),
  //   setUser: () => set((state: StateType) => ({ bears: state.user })),
  removeUserInfo: () => set({ user: undefined }),
}));

export default useStore;
