/**
 * This component manages user state with anything for which you need actual frontend features.
 *
 * For example, it checks on load, whether the stored user is still logged in and needs a refresh.
 */

import { useEffect } from "react";
import { addError } from "../../utils/errors";
import { authenticationService, RefreshTokenInvalidError } from "../../_services/authenticationService";
import LoginForm, { NOTIFICATION_ID_LOGIN_EXPIRED } from "./LoginForm";

export default () => {
  useEffect(() => {
    // Check whether we need to refresh the token
    const user = authenticationService.currentUser();

    if (!user) {
      return;
    }

    if (!user.tokenExpires) {
      // If you have invalid date, just remove it.
      authenticationService.removeUser();
    }

    const now = Date.now();
    const microsecondsToExpiration = new Date(user.tokenExpires).getTime() - now;
    if (microsecondsToExpiration < 5 * 60 * 1000) {
      // Less than 5m to token expiration. Refresh!
      authenticationService.refresh().catch((err) => {
        if (err instanceof RefreshTokenInvalidError) {
          addError("Ihr Login ist abgelaufen. Bitte loggen Sie sich erneut ein.", { id: NOTIFICATION_ID_LOGIN_EXPIRED });
          // The token is not valid, anymore
          authenticationService.removeUser();
        }
      });
    }
  }, []);

  return null;
};
