/**
 * This service provides an adapter for user management, using the local storage and the api endpoints.
 * It has to dependency on react. The react side of things is implemented in UserStateManager Component.
 */

import { AxiosError } from "axios";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "./localStorageService";
import { urlService } from "./urlService";
import useUserStore from "./userStore";

const CURRENT_USER_LOCAL_STORAGE_KEY = "currentUser";

export class MissingActivationError extends Error {}
export class RefreshTokenInvalidError extends Error {}

export type UserDataType = {
  name: string;
  surname: string;
  /** The username associated to the user. TODO: Is this necessary and still used? */
  username: string;
  /** The name of the company, if present */
  companyName?: string;
  isAdmin: boolean;
  /** ISO-8601 string of when the JWT will expire */
  tokenExpires: string;
};

async function login(username: string, password: string) {
  let url = urlService.getUrl("/user/login");

  const response = await urlService
    .getAxios()
    .post(
      url,
      {
        user: username,
        password: password,
      },
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
        withCredentials: true, // This is necessary so that the response cookie is set
      }
    )
    .catch((err) => {
      if (err.response?.status === 401) {
        if (err.response.data.error === "missing-activation") {
          throw new MissingActivationError("Der User wurde noch nicht aktiviert. Bitte prüfen Sie Ihr Email-Postfach");
        }
        throw new Error("Login fehlgeschlagen. Die Kombination aus Email und Passwort ist nicht korrekt.");
      }
      if (err.response && err.response.data && err.response.data.displayMessage) {
        throw new Error(err.response.data.displayMessage);
      }
      if (err.response?.status == 504) {
        throw new Error("Der Server konnte nicht erreicht werden. Bitte prüfen Sie Ihre Internetverbindung.");
      }
      throw new Error("Unbekannter Fehler");
    });

  if (!response || response.status >= 400) {
    throw new Error("Unbekannter Fehler");
  }

  setUser(response.data as UserDataType);
}

async function logout() {
  // log user out locally
  removeUser();

  let url = urlService.getUrl("/user/logout");

  let response = await urlService
    .getAxios()
    .post(
      url,
      {
        // We don't send any content as it is not expected. The user is in the Cookie
      },
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      }
    )
    .catch((err) => {
      // FIXME: Better error
      console.log(err);
    });

  return response;
}

/** Calls the api to use the refresh token for obtaining a new JWT
 *  Stores the returned user date in LocalStorage and the store
 */
async function refresh() {
  let url = urlService.getUrl("/user/refresh-token");
  let response = await urlService
    .getAxios()
    .get(url, {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        "Content-Type": "application/json",
      },
      withCredentials: true, // This is necessary so that the response cookie is set
    })
    .catch((err: AxiosError) => {
      if (err.response?.status === 401 && err.response?.data.message == "not_logged_in") {
        throw new RefreshTokenInvalidError(err.response?.data.displayMessage);
      }
    });

  if (!response || response.status >= 400) {
    removeUser();
    throw new Error("Call to Login api failed");
  }

  setUser(response.data as UserDataType);
}

function isLoggedIn() {
  let value = getLocalStorageItem(CURRENT_USER_LOCAL_STORAGE_KEY);
  if (!value) {
    return false;
  }

  const user = JSON.parse(value);

  if (!user?.tokenExpires) {
    return false;
  }

  const tokenExpires = new Date(user.tokenExpires).getTime();
  return tokenExpires > Date.now();
}

async function changePassword(old_password: string, new_password: string) {
  let url = urlService.getUrl("/user/change-password");
  let response = await urlService
    .getAxios()
    .post(
      url,
      { old_password, new_password },
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
        withCredentials: true, // This is necessary so that the response cookie is set
      }
    )
    .catch((err) => {
      // FIXME: Better error
      console.log(err);
    });

  if (!response || response.status !== 200) {
    throw new Error("Call to Login api failed");
  }

  return true;
}

const resendActivationMail = async (email: string) => {
  let url = urlService.getUrl("/user/resend-activation-mail");
  let response = await urlService
    .getAxios()
    .post(
      url,
      { user: email },
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
        withCredentials: true, // This is necessary so that the response cookie is set
      }
    )
    .catch((err) => {
      // FIXME: Better error
      console.log(err);
    });

  if (!response || response.status !== 200) {
    throw new Error(response?.data?.displayMessage);
  }

  return true;
};

const requestResetPassword = async (email: string) => {
  let url = urlService.getUrl("/user/request-reset-password");
  let response = await urlService
    .getAxios()
    .post(
      url,
      { user: email },
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
        withCredentials: true, // This is necessary so that the response cookie is set
      }
    )
    .catch(() => {
      throw new Error("Fehler beim Senden der Anfrage");
    });

  if (!response || response.status !== 200) {
    throw new Error(response?.data?.displayMessage);
  }

  return true;
};

const confirmResetPassword = async (token: string, password: string) => {
  await urlService.sendStdAxiosRequest("POST", "/user/confirm-reset-password", { token, password });
};

function getJWTValidityInSeconds() {
  let value = getLocalStorageItem(CURRENT_USER_LOCAL_STORAGE_KEY);
  if (!value) {
    return 0;
  }
  const user = JSON.parse(value) as UserDataType;
  const tokenExpires = new Date(user.tokenExpires);
  const now = new Date();
  // getTime returns time in miliseconds
  return (tokenExpires.getTime() - now.getTime()) / 1000;
}

/** Returns the current User if one is present in the local storage. Note: This does NOT mean that the user is currently logged in. Use isLoggedIn for that. */
function currentUser(): UserDataType | undefined {
  let value = getLocalStorageItem(CURRENT_USER_LOCAL_STORAGE_KEY);
  if (!value) {
    return undefined;
  }
  return JSON.parse(value);
}

const setUser = (user: UserDataType) => {
  // The user is stored in localStorage to survive sessions
  setLocalStorageItem(CURRENT_USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
  // The user is also stored in the store to be available to the components
  useUserStore.setState({ user });
};

const removeUser = () => {
  removeLocalStorageItem(CURRENT_USER_LOCAL_STORAGE_KEY);
  useUserStore.setState({ user: undefined });
};

export const authenticationService = {
  login,
  logout,
  refresh,
  isLoggedIn,
  currentUser,
  changePassword,
  getJWTValidityInSeconds,
  resendActivationMail,
  resetPassword: requestResetPassword,
  removeUser,
  confirmResetPassword,
};
