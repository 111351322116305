import axios from "axios";
import { authenticationService, RefreshTokenInvalidError } from "./authenticationService";

export class UrlServiceError extends Error {
  resposeStatus: number | undefined;
  body: Object | undefined;

  constructor(message: string, body?: Object | undefined, resposeStatus?: number) {
    super(message);
    this.body = body;
    this.resposeStatus = resposeStatus;
  }
}

// function getUrl (raw_url) {
const getUrl = (raw_url: string) => {
  if (!raw_url.startsWith("/")) {
    raw_url = "/" + raw_url;
  }

  if (!raw_url.startsWith("/api")) {
    raw_url = "/api" + raw_url;
  }

  // if (process.env.GATSBY_API_URL) {
  //     raw_url = process.env.GATSBY_API_URL + raw_url
  // }

  return raw_url;
};

/**
 * Function to get a standardized version of axios. This allows to set credentials in a centralized way
 */
const getAxios = () => {
  const axiosInstance = axios.create({
    withCredentials: true,
  });

  // Interceptor: Try to get a refresh token when needed.
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (/* originalConfig.url !== getUrl("/api/user/login") &&  */ err.response) {
        if (err.response.status === 401 && err.response.data?.message === "invalid_token" && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            // Call refresh and then return the axiosInstance to try again.
            await authenticationService.refresh();
            return axiosInstance(originalConfig);
          } catch (error) {
            if (error instanceof RefreshTokenInvalidError) {
              // originalConfig._retry = false;
            } else {
              return Promise.reject(error);
            }
          }
        }
      }
      return Promise.reject(err);
    }
  );

  return axiosInstance;
};

const sendStdAxiosRequest = async (method: "GET" | "POST" | "PUT" | "DELETE", rawUrl: string, data: Object) => {
  const url = getUrl(rawUrl);
  const response = await getAxios()
    .request({
      method,
      url,
      headers: {
        // Overwrite Axios's automatically set Content-Type
        "Content-Type": "application/json",
      },
      withCredentials: true, // This is necessary so that the response cookie is set
      data,
    })
    .catch((err) => {
      // if (err.response) {
      throw new UrlServiceError(err.response?.data?.displayMessage || "Unbekannter Fehler", err.response?.data, err.response?.status);
      // } else {
      //   return new UrlServiceError("Unbekannter Fehler");
      // }
    });

  return response;
};

export const urlService = {
  getUrl,
  getAxios,
  sendStdAxiosRequest,
};
