import { ExitToApp } from "@mui/icons-material";
import { Link } from "gatsby";
import React from "react";
import shallow from "zustand/shallow";
import { UserDataType } from "../../_services/authenticationService";
import useStore from "../../_services/userStore";

const displayname = (user: UserDataType) => {
  return user.name ? `${user.name} ${user.surname}` : user.username;
};

const role = (user: UserDataType) => {
  if (user.isAdmin) {
    return "admin";
  }
  return user.companyName;
};

const UserStatus = () => {
  // const [{ currentUser }] = useContext(Context);
  const [currentUser] = useStore((store) => [store.user], shallow);

  return (
    <div className="flex flex-row items-center h-8" style={{ float: "right" }}>
      {currentUser && (
        <div className="flex-none">
          <Link to="/profile">
            <div
              style={{
                textAlign: "center",
                display: "inline-block",
                marginRight: "10px",
              }}
            >
              {displayname(currentUser)}
              <br />
              {role(currentUser) ? <span style={{ fontSize: "small" }}>({role(currentUser)})</span> : null}
            </div>
          </Link>
        </div>
      )}
      <div
        className="flex-none"
        style={{
          display: "inline-block",
        }}
      >
        {currentUser ? (
          <Link to="/logout">
            <ExitToApp />
          </Link>
        ) : (
          <Link to="/login">login</Link>
        )}
      </div>
    </div>
  );
};

export default UserStatus;
