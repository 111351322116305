import create from "zustand";
import { v4 as uuidv4 } from "uuid";
import { AxiosError } from "axios";
import { UrlServiceError } from "../_services/urlService";

const ERROR_DISPLAY_DURATION = 5000; // Dauer in Milisekunden

type NotificationType = "error" | "success";

type Notification = {
  type: NotificationType;
  text: string;
  id: string;
};

type NotificationConfig = {
  id?: string;
  // tags: string[];
};

interface NotificationState {
  notifications: Notification[];
  // removeNotification: (id: string) => void;
  // addSuccess: (text: string) => void;
  // addError: (text: string) => void;
}

export const useNotificationStore = create<NotificationState>((set, get) => ({
  notifications: [],
  // addSuccess: (text: string) => {
  //   const id = uuidv4();
  //   const newNotification: Notification = {
  //     type: "success",
  //     id,
  //     text,
  //   };
  //   set({ notifications: [...(get().notifications || []), newNotification] });

  //   setTimeout(() => {
  //     // We would call removeNotification but this executed the useNotificationStore hook. This would not be allowed here.
  //     set({ notifications: get().notifications?.filter((notification) => notification.id !== id) });
  //   }, ERROR_DISPLAY_DURATION);
  // },
  // addError: (text: string) => {
  //   const id = uuidv4();
  //   const newNotification: Notification = {
  //     type: "error",
  //     id,
  //     text,
  //   };
  //   set({ notifications: [...(get().notifications || []), newNotification] });
  // },
  // removeNotification: (id: string) => {
  //   set({ notifications: get().notifications?.filter((notification) => notification.id !== id) });
  // },
}));

export const addError = (text: string, config?: NotificationConfig) => {
  addNotification("error", text, config?.id);
};

export const addAxiosError = (err: AxiosError, config?: NotificationConfig) => {
  var message = err.response?.data?.displayMessage || "Unbekannter Fehler";
  if (err.response?.status === 401) {
    message = "Bitte loggen Sie sich ein.";
  }
  addError(message, config);
};

export const addUrlServiceError = (err: UrlServiceError, config?: NotificationConfig) => {
  addError(err.message, config);
};

export const addSuccess = (text: string, config?: NotificationConfig) => {
  const id = addNotification("success", text, config?.id);
  setTimeout(() => {
    // We would call removeNotification but this executed the useNotificationStore hook. This would not be allowed here.
    removeNotification(id);
  }, ERROR_DISPLAY_DURATION);
};

const addNotification = (type: NotificationType, text: string, id?: string): string => {
  const state = useNotificationStore.getState();
  const newID = id || uuidv4();
  const notification: Notification = {
    type,
    text,
    id: newID,
  };
  state.notifications = [...state.notifications, notification];
  useNotificationStore.setState({ ...state });

  return newID;
};

export const removeNotification = (id: string) => {
  const state = useNotificationStore.getState();
  state.notifications = state.notifications.filter((notification) => notification.id !== id);

  useNotificationStore.setState({ ...state });
};
