import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import * as yup from "yup";

import { Link } from "gatsby";
import { navigate } from "gatsby-link";
import _ from "lodash";
import { addError, removeNotification } from "../../utils/errors";
import { authenticationService, MissingActivationError } from "../../_services/authenticationService";
import TextFormikField from "../FormikFields/TextFormikField";
import WaitingAnimation from "../WaitingAnimation";

const NOTIFICATION_ID_LOGIN_ERROR = "login_error";
export const NOTIFICATION_ID_LOGIN_EXPIRED = "login_expired";

type LoginFormPropType = {
  targetOnLogin: string;
  onActivationMissingError: (email: string) => void;
};

const LoginForm = ({ targetOnLogin, onActivationMissingError }: LoginFormPropType) => {
  const LoginSchema = yup.object().shape({
    username: yup.string().email("Invalid email").required("Bitte geben Sie Ihre Email an"),
    password: yup.string().required("Bitte geben Sie ein Passwort ein"),
  });

  useEffect(() => {
    // When this Login is shown, the error about expired login should disappear
    removeNotification(NOTIFICATION_ID_LOGIN_EXPIRED);
  });

  return (
    <div className="w-full block">
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
          username = username.toLowerCase(); // Only lowercase is valid.
          setStatus();
          authenticationService
            .login(username, password)
            .then((res) => {
              removeNotification(NOTIFICATION_ID_LOGIN_ERROR);
              navigate(targetOnLogin || "/");
            })
            .catch((err) => {
              setSubmitting(false);
              if (err instanceof MissingActivationError) {
                onActivationMissingError(username);
              }
              addError(err.message, { id: NOTIFICATION_ID_LOGIN_ERROR });
            });
        }}
      >
        {({ isSubmitting, errors, values }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="username">Email</label>
              <Field type="email" component={TextFormikField} className="w-full" name="username" />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field component={TextFormikField} className="w-full" name="password" type="password" />
            </div>
            <div className="form-group">
              {isSubmitting && (
                <div>
                  <WaitingAnimation />
                </div>
              )}
              <div className="flex flex-row items-end">
                <div className="flex-1">
                  <Link className="align-middle" to={`/user/request-reset-password?user=${values.username}`}>
                    Passwort vergessen
                  </Link>
                </div>
                <div className="flex-none">
                  <button className="button button-primary" type="submit" disabled={isSubmitting || _.some(errors)}>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
