import { Link } from "gatsby";
import React from "react";

export default function Navigation() {
  return (
    <nav>
      <Link to="/">Start</Link>
      <Link to="/interaktiv">Interaktiv</Link>
      <Link to="/upload">Upload</Link>
      <Link to="/uebersicht">Übersicht</Link>
      <Link to="/faq">FAQ</Link>
    </nav>
  );
}
